//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getDictionary,
         getLang } from '~/utils/butterUtils'
export default {
  head () {
    return {
      title: this.pageTitle,
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/faqs/index.vue asyncData ')
  },
  data () {
    return {
      FAQList: [],
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    pageTitle () {
      return process.env.siteTitle + " | " +  this.dict.P16
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    butterFaqQuestions () {
      return this.$store.state.butterFaqQuestions
    },
  },
  methods: {
    showFAQAnswer (slug) {
      for (let ii=0; ii<this.FAQList.length; ii++) {
        if (this.FAQList[ii].isopen) {
          this.FAQList[ii].isopen = false /** toggle the display of an already open answer */
        } else {
          this.FAQList[ii].isopen = this.FAQList[ii].slug===slug
        }
      }
    },
    makeFAQList () {
      let FAQArray=[]
      const subset = this.butterFaqQuestions.filter(faq => faq.show_to_new_voters==="yes")
      for (let ii=0; ii<subset.length; ii++) {
        let FAQ={}
        FAQ.slug = subset[ii].slug
        FAQ.icon = subset[ii].icon ? subset[ii].icon : this.butterVFAGraphics.fpca_blank
        FAQ.question = subset[ii]["question_"+this.lang]
        const qa = subset[ii]["questionalias_"+this.lang]
        FAQ.questionalias = qa ? qa : FAQ.question
        FAQ.answer = subset[ii]["answer_"+this.lang]
        FAQ.isopen = false
        FAQ.questionorder = subset[ii].questionorder
        FAQArray.push(FAQ)
      }
      this.FAQList = FAQArray.sort((a,b)=>{ return (a.questionorder<b.questionorder)?-1:1 })
    },
  },
  mounted() {
    this.makeFAQList()
  },
  watch: {
    lang () {
      this.makeFAQList()
    },
  },
}
